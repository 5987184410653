import "./theme.scss";
import JTRadioSelect from "./components/JTRadioSelect/JTRadioSelect";
import JTPasswordField from "./components/JTPasswordField/JTPasswordField";
import JTCustomProgress from "./components/JTCustomProgress/JTCustomProgress";
import JTOffcanvas from "./components/JTOffcanvas/JTOffcanvas";
import JTClearInput from "./components/JTClearInput/JTClearInput";
import JTOFCCPActivitiesLink from "./components/JTOFCCPActivitiesLink/JTOFCCPActivitiesLink";

// @ts-ignore
import { version } from "./../../../package.json";
import SelectBoxFactory from "./components/SelectBox/SelectBox";

import "./necessary-polyfills";

function PhoneFactory(root) {
  return import("./components/JTPhone/JTPhone")
    .then((module) => {
      return new module.default(root);
    })
    .catch(console.error);
}

(<any>window).JTComponents = {
  version,
  // @ts-ignore
  env: PUBLIC_JT_BUILD_ENV, //FIXME: fix TS and Define plugin issues
  JTCustomProgress,
  JTRadioSelect,
  JTPasswordField,
  JTOffcanvas,
  JTPhone: {
    create: PhoneFactory,
  },
  JTClearInput,
  JTOFCCPActivitiesLink,
  JTSelectBox: SelectBoxFactory,
};
