import JT_ENV_VARS from "../../../../../JT_ENV_VARS";

const ENV_VARS = JT_ENV_VARS[PUBLIC_JT_BUILD_ENV];
const NAVBAR_EVENTS = {
  REQUEST: "requestuserinfo:jt:navbar",
  RESPONSE: "userinfo:jt:navbar",
};

// window.dispatchEvent(new CustomEvent("requestuserinfo:jt:navbar"));

/*
 *
 * request nav data
 * receive nav data
 * send out request to get hash for url
 * listen for clicks for certain ofccp links and append then
 * if the appending or getting the hash fails, show a warning
 *
 * */

export default class JTOFCCPActivitiesLink {
  static init() {
    window.addEventListener("click", JTOFCCPActivitiesLink.handleClick);
  }

  static handleClick(e) {
    let target = e.target;
    if (target.tagName !== "A") {
      target = target.closest("a");
    }
    if (target) {
      if (target.href !== undefined) {
        if (
          target.href.includes("outreach-notes") ||
          target.href.includes("ofccp-activities")
        ) {
          e.preventDefault();
          console.log("ofccp click");
          JTOFCCPActivitiesLink.requestNavbarData();
        }
      }
    }
  }

  static requestNavbarData() {
    window.addEventListener(
      NAVBAR_EVENTS.RESPONSE,
      JTOFCCPActivitiesLink.receivedNavbarData
    );
    window.dispatchEvent(new CustomEvent(NAVBAR_EVENTS.REQUEST));
  }

  static receivedNavbarData(customEvent) {
    const jtToken = customEvent.detail.jtToken;
    const user_id = customEvent.detail.user_id;
    JTOFCCPActivitiesLink.fetchAppHandoffToken(jtToken, user_id);
  }

  static fetchAppHandoffToken(jtToken, user_id) {
    fetch(`${ENV_VARS.API.GUID_FROM_UID}/Users/GetUserToken/${user_id}`, {
      headers: new Headers({
        Authorization: `Bearer ${jtToken}`,
        "Content-Type": "text/plain",
      }),
    })
      .then((resp) => resp.text())
      .then((data) => {
        JTOFCCPActivitiesLink.redirectToActivity(data, user_id, jtToken);
      })
      .catch((err) => console.error(err));
  }

  static redirectToActivity(token, userId, jtToken) {
    let encrypted = null;
    try {
      fetch(ENV_VARS.API.ENCRYPT, {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${jtToken}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          userId,
          token,
        }),
      })
        .then((resp) => resp.json())
        .then((data) => {
          encrypted = data.response;
          encrypted = encodeURIComponent(encrypted);
          const url = `${ENV_VARS.URLS.OFCCP_OUTREACH}/${encrypted}`;
          window.location.assign(url);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  }
}
