const PUBLIC_JT_ENV_VARS ={
  development: {
    URLS: {
      OFCCP_OUTREACH: "https://outreach.qa-aws.jobtarget.com"
    },
    API: {
      ENCRYPT: "https://outreach-api.qa-aws.jobtarget.com/encrypt",
      GUID_FROM_UID: "https://qa-platformdashboardapi.jobtarget.com/api",
    }
  },
  production:{
    URLS: {
      OFCCP_OUTREACH: "https://ofccp-activities.jobtarget.com"
    },
    API: {
      ENCRYPT: "https://outreach-api.jobtarget.com/encrypt",
      GUID_FROM_UID: "https://platformdashboardapi.jobtarget.com/api",
    }
  }
}

export default PUBLIC_JT_ENV_VARS;
